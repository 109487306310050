import React from "react";
import { useLanguageContext } from "../contexts/language-context";

const HolidaysModal = () => {
  const isSpanish = useLanguageContext();

  return (
    <table id="holiday-hours-table" className={`table table-bordered table-fixed`}>
      <thead>
        <tr className="text-center">
          <th title="Holiday">{isSpanish ? "Vacaciones" : "HOLIDAY"}</th>
          <th title="2024 Date">{isSpanish ? "2024 Fecha" : "2024 DATE"}</th>
        </tr>
      </thead>
      {isSpanish ? (
        <tbody>
          <tr>
            <td className="font-weight-bold">Día de Año Nuevo</td>
            <td>Lunes 1 de enero</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Día de Martin Luther King</td>
            <td>Lunes 15 de enero</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Día del Presidente</td>
            <td>Lunes 19 de febrero</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Día Conmemorativo</td>
            <td>Lunes 27 de mayo</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Diecinueve de junio</td>
            <td>Miércoles 19 de junio</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Día de la Independencia</td>
            <td>Jueves 4 de julio</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Día laboral</td>
            <td>Lunes 2 de septiembre</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Día de los Pueblos Indígenas</td>
            <td>Lunes 14 de Octubre</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Día de los veteranos</td>
            <td>Lunes 11 de noviembre</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Acción de gracias</td>
            <td>Jueves 28 de noviembre</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Navidad</td>
            <td>Miércoles 25 de diciembre</td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td className="font-weight-bold">New Year's Day</td>
            <td>Monday, January 1</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Martin Luther King Day</td>
            <td>Monday, January 15</td>
          </tr>
          <tr>
            <td className="font-weight-bold">President's Day</td>
            <td>Monday, February 19</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Memorial Day</td>
            <td>Monday, May 27</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Juneteenth</td>
            <td>Wednesday, June 19</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Independence Day</td>
            <td>Thursday, July 4</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Labor Day</td>
            <td>Monday, September 2</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Indigenous People's Day</td>
            <td>Monday, October 14</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Veterans Day</td>
            <td>Monday, November 11</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Thanksgiving</td>
            <td>Thursday, November 28</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Christmas</td>
            <td>Wednesday, December 25</td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default HolidaysModal;
