import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

/**
 * This is the component to render the branch services at mobile size.
 *
 * @param {Object} props - The object containing props.
 * @param {"en" | "es"} props.lang - "en"/"es" Language to show.
 * @param {string} props.listClass - Classes for <ul> list container element.
 * @param {string} props.itemClass - Classes for <li> list elements.
 * @param {boolean} props.twoColumns - Whether we show the service sin 2 columns or not.
 * @param {string} props.colClass - Classes for div columns container when showing 2 columns.
 * @param {Object} props.services - Object for the services branch details.
 *   - `TellerServices`: Boolean prop to indicate whether the service is available or not.
 *   - `WalkUpATM`: Boolean prop to indicate whether the service is available or not.
 *   - `DriveThroughATM`: Boolean prop to indicate whether the service is available or not.
 *   - `DriveThroughBanking`: Boolean prop to indicate whether the service is available or not.
 *   - `SafeDepositBoxes`: Boolean prop to indicate whether the service is available or not.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const BranchServices = ({
  lang = "en",
  listClass = "",
  itemClass = "",
  twoColumns = false,
  colClass = "col-sm-12 col-lg-6",
  services = {
    TellerServices: true,
    WalkUpATM: true,
    DriveThroughATM: false,
    DriveThroughBanking: false,
    SafeDepositBoxes: false
  }
}) => {
  const servicesData = {
    TellerServices: {
      en: "Teller Services",
      es: "Servicios de cajero"
    },
    SafeDepositBoxes: {
      en: "Safe Deposit Boxes",
      es: "Cajas de seguridad"
    },
    WalkUpATM: {
      en: "Walk-up ATM",
      es: "Cajero automático"
    },
    DriveThroughATM: {
      en: "Drive-through ATM",
      es: "Subir cajero automático"
    },
    DriveThroughBanking: {
      en: "Drive-through Banking",
      es: "Banca de autoservicio"
    }
  };

  return (
    <>
      {twoColumns ? (
        <div className="row">
          <div className={colClass}>
            <ul className={`${listClass} fa-ul ml-4`}>
              {services.TellerServices && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.TellerServices[lang]}
                </li>
              )}
              {services.SafeDepositBoxes && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.SafeDepositBoxes[lang]}
                </li>
              )}
              {services.WalkUpATM && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.WalkUpATM[lang]}
                </li>
              )}
            </ul>
          </div>
          <div className={colClass}>
            <ul className={`${listClass} fa-ul ml-4`}>
              {services.DriveThroughATM && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughATM[lang]}
                </li>
              )}
              {services.DriveThroughBanking && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughBanking[lang]}
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <ul className={`${listClass} fa-ul ml-4`}>
              {services.TellerServices && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.TellerServices[lang]}
                </li>
              )}
              {services.SafeDepositBoxes && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.SafeDepositBoxes[lang]}
                </li>
              )}
              {services.WalkUpATM && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.WalkUpATM[lang]}
                </li>
              )}
              {services.DriveThroughATM && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughATM[lang]}
                </li>
              )}
              {services.DriveThroughBanking && (
                <li className={itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughBanking[lang]}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
export default BranchServices;

BranchServices.propTypes = {
  lang: PropTypes.oneOf(["en", "es"]),
  listClass: PropTypes.string,
  itemClass: PropTypes.string,
  twoColumns: PropTypes.bool,
  colClass: PropTypes.string,
  services: PropTypes.shape({
    TellerServices: PropTypes.bool,
    WalkUpATM: PropTypes.bool,
    DriveThroughATM: PropTypes.bool,
    DriveThroughBanking: PropTypes.bool,
    SafeDepositBoxes: PropTypes.bool
  })
};
