import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * This is the component to render the branch daily hours at mobile size.
 *
 * @param {Object} props - The object containing props.
 * @param {boolean} props.AllClosed - Boolean value for the branch all days closed.
 * @param {"en" | "es"} props.lang - "en"/"es" Language to show.
 * @param {string} props.Monday - "9 AM-5 PM" open hours or "Closed".
 * @param {string} props.Tuesday - "9 AM-5 PM" open hours or "Closed".
 * @param {string} props.Wednesday - "9 AM-5 PM" open hours or "Closed".
 * @param {string} props.Thursday - "9 AM-5 PM" open hours or "Closed".
 * @param {string} props.Friday - "9 AM-5 PM" open hours or "Closed".
 * @param {string} props.Saturday - "9 AM-5 PM" open hours or "Closed".
 * @param {string} props.Sunday - "9 AM-5 PM" open hours or "Closed".
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const DailyHours = ({
  AllClosed = false,
  lang = "en",
  Monday = "9 AM-5 PM",
  Tuesday = "9 AM-5 PM",
  Wednesday = "9 AM-5 PM",
  Thursday = "9 AM-5 PM",
  Friday = "9 AM-5 PM",
  Saturday = "Closed",
  Sunday = "Closed"
}) => {
  const SENT_DAYS = {
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
  };

  const [today, setToday] = useState("");
  function getWeekDay(date) {
    let day = date.getDay();
    return [weekdays.en[day], weekdays.es[day]];
  }
  useEffect(() => {
    let weekDay = getWeekDay(now);
    setToday(lang === "es" ? weekDay[1] : weekDay[0]);
  }, []);

  let weekdays = {
    en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    es: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"]
  };

  let now = new Date();

  return (
    <>
      {AllClosed ? (
        <p className="text-danger">Closed</p>
      ) : (
        <>
          {weekdays.en.map(function (day, i) {
            return (
              <div key={i} className="d-flex justify-content-between mb-2">
                <div className={`${today === weekdays[lang][i] ? "font-weight-bold" : ""} mr-2`}>
                  {weekdays[lang][i]}
                </div>
                <div className={`${today === weekdays[lang][i] ? "font-weight-bold" : ""}`}>{SENT_DAYS[day]}</div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default DailyHours;

DailyHours.propTypes = {
  AllClosed: PropTypes.bool.isRequired,
  lang: PropTypes.oneOf(["en", "es"]),
  Monday: PropTypes.string.isRequired,
  Tuesday: PropTypes.string.isRequired,
  Wednesday: PropTypes.string.isRequired,
  Thursday: PropTypes.string.isRequired,
  Friday: PropTypes.string.isRequired,
  Saturday: PropTypes.string.isRequired,
  Sunday: PropTypes.string.isRequired
};
