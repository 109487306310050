/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import postscribe from "postscribe";
import { getSrc, StaticImage } from "gatsby-plugin-image";
import BestBanksDefault from "../best-banks/best-banks-default";
import List from "../custom-widgets/list";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";
import WaFdMobileApp from "../../images/wafd-mobile-app-102524.png";

// Styles
import "./branch.bootstrap.scss";

// Components
import Breadcrumb from "../breadcrumb/breadcrumb-auto";
import Button from "../custom-widgets/button";
import Icon from "../custom-widgets/icon";
import DailyHours from "../branch-info/daily-hours";
import BranchServices from "../branch-info/branch-services";
import SEO from "../../components/seo/seo";
import Modal from "react-bootstrap/Modal";
import HolidaysModal from "../holidays-modal";
import styles from "../../pages/commercial-banking/treasury-management-services/express.module.scss";
import MobileAppDownloadBtn from "../mobile-online-banking/mobile-app-download-btn";
import NotificationAlert from "../../components/notifications/notification-alert";
import BankerHeadshot from "../../components/banker-headshot";
import LoanOfficerSearch from "../loan-officers/loan-officer-search";
import nameToId from "../../helpers/nameToId";

export const query = graphql`
  query branchQuery($path: String) {
    branch: strapiBranchLocations(PageURL: { eq: $path }) {
      PageURL
      Title
      BranchId
      YextCode
      MSBookingsBranchName
      Address {
        City
        State
        StreetAddress
        ZipCode
        BranchGeoLocation {
          id
          Lat
          Lng
          Zoom
        }
      }
      PhoneNumber
      EmailAddress
      DisruptionNotice
      BranchImageUrl
      BranchServices {
        TellerServices
        WalkUpATM
        DriveThroughATM
        DriveThroughBanking
        SafeDepositBoxes
      }
      LobbyHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      DriveUpHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      SEO {
        MetaDescription
        MetaTitle
        Schema {
          address {
            _type
            addressCountry
            addressLocality
            addressRegion
            postalCode
            streetAddress
          }
          _context
          _type
          amenityFeature {
            _type
            hoursAvailable {
              _type
              closes
              dayOfWeek
              opens
            }
            name
            value
          }
          branchCode
          email
          geo {
            _type
            latitude
            longitude
          }
          image
          name
          openingHoursSpecification {
            _type
            closes
            dayOfWeek
            opens
          }
          parentOrganization {
            _type
            logo
            name
            url
          }
          telephone
          url
        }
      }
      OgImageUrl
      branch_state {
        StateCode
        Slug
      }
      loan_officers {
        FirstName
        LastName
        JobTitle
        EmailAddress
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      mortgage_bankers {
        Active
        FirstName
        LastName
        JobTitle
        EmailAddress
        PhoneNumber
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    defaultBranchPhoto: file(relativePath: { eq: "branches/wafd-bank-branch-default-1000.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 1200
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
  }
`;

const BranchTemplate = ({ location, data }) => {
  const branch = data.branch;
  const StateCode = branch.branch_state.StateCode;

  useEffect(() => {
    branch.YextCode &&
      postscribe(
        "#branch-reviews",
        `<script src=https://sites.yext.com/205067-reviews.js?storeCode=${branch.YextCode} ></script>`
      );
  }, []);

  // 2024 Holidays Modal
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const handleCloseModal = () => setShowHolidayModal(false);
  const handleShowModal = (e) => {
    e.preventDefault();
    setShowHolidayModal(true);
  };

  const [showLobbyHours, setShowLobbyHours] = useState(false);
  const handleShowLobbyHours = () => {
    setShowLobbyHours(!showLobbyHours);
  };

  const [showDriveUpHours, setShowDriveUpHours] = useState(false);
  const handleShowDriveUpHours = () => {
    setShowDriveUpHours(!showDriveUpHours);
  };

  // TODO: the case sensitivity of these keys must match what is in Strapi data, too brittle.
  let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let now = new Date();

  const [today, setToday] = useState("");
  function getWeekDay(date) {
    let day = date.getDay();
    return weekdays[day];
  }
  useEffect(() => {
    let weekDay = getWeekDay(now);
    setToday(weekDay);
  }, []);

  const todaysLobbyHours = branch.LobbyHours.AllClosed
    ? "Closed"
    : branch.LobbyHours[today] !== null
    ? branch.LobbyHours[today]
    : "Unknown";

  const todaysDriveUpHours = branch.DriveUpHours?.AllClosed
    ? "Closed"
    : branch.DriveUpHours !== null && branch.DriveUpHours[today] !== null
    ? branch.DriveUpHours[today]
    : "Unknown";

  const todaysLobbyHoursHtml = todaysLobbyHours?.match(/closed/i)
    ? "Lobby <strong>Closed Today</strong>"
    : "Lobby Hours Today: <strong>" + todaysLobbyHours + "</strong>";

  const todaysDriveUpHoursHtml = todaysDriveUpHours?.match(/closed/i)
    ? "Drive Thru <strong>Closed Today</strong>"
    : "Drive Thru Hours Today: <strong>" + todaysDriveUpHours + "</strong>";

  const openAccountBtnData = {
    id: "open-account-cta",
    type: "link",
    class: "btn-link btn-block",
    text: "Open an Account",
    url: "/open-bank-account-online"
  };

  const defaultMetaTitle = "Bank in " + branch.Address.City + " ," + branch.Address.State;
  const defaultMetaDescription =
    "Welcome to WaFd Bank we provide checking, savings, and mortgage services in the communities we serve. Visit our " +
    branch.Address.City +
    " " +
    branch.Address.State +
    " " +
    "branch!";

  // console.log(
  //   `Found ${branch.loan_officers.length} Personal Bankers and ${branch.mortgage_bankers.length} Mortgage Bankers`
  // );
  let loanOfficers = [...branch.loan_officers, ...branch.mortgage_bankers.filter((mb) => mb.Active === true)];
  // console.log(`Found ${loanOfficers.length} Loan Officers (total)`);

  // Find the branch manager so we can display them first
  const branchManager = loanOfficers.find((lo) => lo.JobTitle === "BranchManager");
  if (loanOfficers && branchManager) {
    loanOfficers = loanOfficers.filter((n) => n.JobTitle !== "BranchManager");
    loanOfficers.unshift(branchManager);
  }

  // Seattle Downtown Branch schema is the default schema
  const defaultSchema = {
    "@context": "http://schema.org",
    "@type": "BankOrCreditUnion",
    name: "Downtown Seattle",
    address: {
      "@type": "PostalAddress",
      streetAddress: "425 Pike St",
      addressLocality: "Seattle",
      addressRegion: "WA",
      addressCountry: "United States",
      postalCode: "98101"
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "47.610385",
      longitude: "-122.335771"
    },
    branchCode: "1006",
    url: "https://www.wafdbank.com/locations/washington/seattle/downtown",
    image: "https://www.wafdbank.com/images/default-source/wafd-logo/branch-locations/wafd-bank-in-washington",
    telephone: "(206) 204-3446",
    email: "downtownseattle.office@wafd.com",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Monday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Tuesday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Wednesday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Thursday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Friday",
        opens: "9:00:00",
        closes: "17:30:00"
      }
    ],
    parentOrganization: {
      "@type": "Organization",
      name: "WaFd Bank",
      logo: "https://www.wafdbank.com/images/default-source/wafd-logo/logos/wafd-bank-logo-vert-stack-png.png",
      url: "https://www.wafdbank.com/",
      sameAs: [
        "https://www.facebook.com/wafdbank",
        "https://twitter.com/wafdbank",
        "https://linkedin.com/company/wafd-bank",
        "https://www.youtube.com/user/wafedweb",
        "https://en.wikipedia.org/wiki/Washington_Federal"
      ]
    }
  };

  function removeUnderscoreFromObj(obj, prop) {
    if (prop.substring(0, 1) === "_") {
      const newProp = "@" + prop.substring(1);
      obj[newProp] = obj[prop];
      delete obj[prop];
    }
    return obj;
  }

  function removeUnderscoreFromArray(array, prop) {
    if (Array.isArray(array[prop])) {
      let a = 0;
      for (a; a < array[prop].length; a++) {
        for (const obj in array[prop][a]) {
          array[prop][a] = removeUnderscoreFromArray(array[prop][a], obj);
          array[prop][a] = removeUnderscoreFromObj(array[prop][a], obj);
        }
      }
    }
    return array;
  }

  function removeUnderscore() {
    let newSchema = { ...branch.SEO.Schema };
    for (const prop in newSchema) {
      for (const childProp in newSchema[prop]) {
        newSchema[prop] = removeUnderscoreFromArray(newSchema[prop], childProp);
        newSchema[prop] = removeUnderscoreFromObj(newSchema[prop], childProp);
      }
      newSchema = removeUnderscoreFromArray(newSchema, prop);
      newSchema = removeUnderscoreFromObj(newSchema, prop);
    }
    return newSchema;
  }

  // provide the static URLs for og:image meta tags
  const branchOgImage = branch.OgImageUrl
    ? `https://www.wafdbank.com` + branch.OgImageUrl
    : branch.BranchImageUrl
    ? `https://www.wafdbank.com${branch.BranchImageUrl}`
    : `https://www.wafdbank.com` + getSrc(data.defaultBranchPhoto);

  const SEOData = {
    title: branch.SEO.MetaTitle || defaultMetaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: branch.SEO.MetaTitle || defaultMetaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: branch.SEO.MetaDescription || defaultMetaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com" + branch.PageURL
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: branchOgImage
      }
    ],
    schema: removeUnderscore(branch.SEO.Schema) || defaultSchema
  };

  const viewInGoogleMapUrl =
    "https://maps.google.com/maps?q=" +
    branch.Address.StreetAddress +
    "%2c" +
    branch.Address.City +
    "%2c" +
    StateCode +
    "%2c" +
    branch.Address.ZipCode;

  const [showLOModalMortgage, setShowLOModalMortgage] = useState(false);
  const handleCloseLOModalMortgage = () => setShowLOModalMortgage(false);
  const handleShowLOModalMortgage = () => {
    setShowLOModalMortgage(true);
  };

  const [showLOModalHomeEquity, setShowLOModalHomeEquity] = useState(false);
  const handleCloseLOModalHomeEquity = () => setShowLOModalHomeEquity(false);
  const handleShowLOModalHomeEquity = () => {
    setShowLOModalHomeEquity(true);
  };

  let iconBlocksData = [
    {
      text: "Open a Personal Account Online",
      src: "/images/icons/branch-personal-accounts-icon.svg",
      url: "/open-bank-account-online"
    },
    {
      text: "Open a Small Business Account Online",
      src: "/images/icons/branch-business-accounts-icon.svg",
      url: "/business-banking/open-business-bank-account-online"
    },
    {
      text: "Already have an Account? Sign up for online access",
      src: "/images/icons/branch-enroll-online-access-icon.svg",
      url: "https://online.wafdbank.com/link/register?application=OLB",
      externalLink: true
    },
    {
      text: "Apply for a Home Loan",
      src: "/images/icons/home-loan.svg",
      onClick: handleShowLOModalMortgage
    },
    {
      text: "Apply for a Home Equity Loan",
      src: "/images/icons/heloc.svg",
      onClick: handleShowLOModalHomeEquity
    }
  ];

  const hasDisruptionNotice = branch && branch.DisruptionNotice && branch.DisruptionNotice.trim().length !== 0;

  useEffect(() => {
    const reviewsContainer = document.getElementById("branch-reviews");
    if (reviewsContainer) {
      // console.log('Found branch-reviews');
      const observer = new MutationObserver((mutationsList, observer) => {
        const reviewTextDiv = reviewsContainer.querySelector(".reviewLink");
        if (reviewTextDiv) {
          // console.log('Found reviewLink inside reviews-container');
          const anchorElement = reviewTextDiv.querySelector("a");
          if (anchorElement) {
            anchorElement.textContent = "Leave a Review";
            // console.log('Anchor text updated');
            observer.disconnect();
          } else {
            // console.log('Anchor element not found inside reviewLink');
          }
        } else {
          // console.log('reviewLink not found inside branch-reviews');
        }
      });

      observer.observe(reviewsContainer, { childList: true, subtree: true });

      return () => observer.disconnect();
    } else {
      // console.log('reviews-container not found');
    }
  }, []);

  const onlineMobileBankingListItems = {
    accountName: "online-mobile-banking-list",
    items: [
      {
        id: 1,
        text: "Pay bills online or with your smartphone"
      },
      {
        id: 2,
        text: "Check your balance, view transactions and transfer money"
      },
      {
        id: 3,
        text: "Deposit checks with your smart phone and mobile photo deposit"
      },
      {
        id: 4,
        text: "Pay friends with person-to-person transfers"
      },
      {
        id: 5,
        text: "Make online wire transfers"
      },
      {
        id: 6,
        text: "Reach your financial goals by setting real-time alerts, managing spending and tracking savings"
      }
    ]
  };

  const mobileAndOnlineBankingFAQs = {
    id: "online-mobile-banking-faq-see-more-faqs-link",
    type: "link",
    url: "/personal-banking/online-banking/faq#online-and-mobile-banking-faq-sections",
    containerClass: "w-100 d-flex d-md-block justify-content-center",
    class: "w-auto btn-anchor-link no-min-width pl-0",
    text: "Mobile Banking FAQs",
    icon: {
      position: "right",
      lib: "far",
      name: "arrow-right",
      class: "mt-1 ml-1"
    }
  };

  const statesWonAward = ["ID", "NM", "NV", "WA"];

  const BestBanksImage = () => (
    <StaticImage
      src="../../images/newsweek-3yr-lockup-600.png"
      alt="WaFd Bank voted Best Bank by Newsweek"
      placeholder="blurred"
      quality="100"
      height={225}
    />
  );

  const BestInStateBanksImage = () => (
    <StaticImage
      src="../../images/forbes-logo-2024-with-shadow.png"
      alt="WaFd Bank voted Best-in-State Bank by Forbes."
      placeholder="blurred"
      quality="100"
      height={225}
    />
  );

  const BusinessReviewAwardImage = () => (
    <img
      src="/images/wafd-bank-idaho-best-regional-bank-2024.png"
      alt="Idaho Business Review Reader Rankings 2024 Best Regional bank Winner badge."
      className="w-100"
    />
  );

  return (
    <SecondaryLanding footerBorder={statesWonAward.includes(StateCode) ? true : false}>
      <SEO {...SEOData} />
      <Breadcrumb {...{ location }} />
      {/* Disruption Notice */}
      {hasDisruptionNotice && (
        <section className="container pb-0">
          <NotificationAlert
            type="warning"
            id="branch-disruption-notification-alert"
            class="mb-0"
            bodyText={branch.DisruptionNotice}
          />
        </section>
      )}
      {/* Branch Info Section */}
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-success">WaFd Bank {branch.Title}</h1>
            <div className="row mb-4">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <a href="#branch-employees" className="btn btn-primary btn-block" id="make-an-appointment-anchor-link">
                  Make an Appointment
                  <Icon name="arrow-down" class="ml-2 mt-1 float-right" lib="far" />
                </a>
              </div>
              <div className="col-lg-6">
                <Button {...openAccountBtnData} />
              </div>
            </div>
            {/* Branch Address */}
            <div className="d-flex mb-3">
              <Icon name="map-marker-alt" lib="far" class="text-primary mr-2 mt-1" />
              <a
                href={viewInGoogleMapUrl}
                className="text-decoration-none"
                id="branch-address-cta"
                target="_blank"
                rel="noopener noreferrer"
              >
                {branch.Address.StreetAddress}, {branch.Address.City},{" "}
                {branch.Address.State === "NewMexico" ? "New Mexico" : branch.Address.State}&nbsp;&nbsp;
                {branch.Address.ZipCode}
              </a>
            </div>
            {/* Today Lobby Hours */}
            <div className="d-flex flex-column flex-sm-row mb-3">
              <div>
                <Icon name="clock" lib="far" class="text-primary mr-2 mt-1" />
                <span
                  id="today-branch-hours"
                  dangerouslySetInnerHTML={{
                    __html: todaysLobbyHoursHtml
                  }}
                ></span>
              </div>
              <div className="ml-sm-2 d-none d-md-flex">
                <a href="#branch-lobby-hours" className="text-decoration-none" id="branch-lobby-hours-anchor-link">
                  <Icon name="arrow-down" class="mr-1" lib="far" />
                  Lobby Hours
                </a>
              </div>
              <div className="ml-sm-2 d-flex d-md-none">
                <button
                  className="btn btn-anchor-link no-min-width px-0 text-left"
                  onClick={handleShowLobbyHours}
                  id="show-hide-lobby-hours"
                >
                  <Icon name={showLobbyHours ? "chevron-up" : "chevron-down"} class="mr-1" lib="fas" />
                  {showLobbyHours ? "Hide Hours" : "See Hours"}
                </button>
              </div>
            </div>
            {showLobbyHours && (
              <div className="mb-4">
                <DailyHours {...branch.LobbyHours} />
                <button
                  className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
                  onClick={handleShowModal}
                >
                  <Icon name="calendar" lib="far" class="mr-2" />
                  2024 Holidays
                </button>
              </div>
            )}
            {/* Today Drive Up Hours */}
            {branch.DriveUpHours && (
              <>
                <div className="d-flex flex-column flex-sm-row my-3">
                  <div>
                    <Icon name="clock" lib="far" class="text-primary mr-2 mt-1" />
                    <span
                      id="today-drive-thru-hours"
                      dangerouslySetInnerHTML={{
                        __html: todaysDriveUpHoursHtml
                      }}
                    ></span>
                  </div>
                  <div className="ml-sm-2 d-none d-md-flex">
                    <a
                      href="#branch-drive-thru-hours"
                      className="text-decoration-none"
                      id="branch-drive-thru-anchor-link"
                    >
                      <Icon name="arrow-down" class="mr-1" lib="far" />
                      Drive Thru Hours
                    </a>
                  </div>
                  <div className="ml-sm-2 d-flex d-md-none">
                    <button
                      className="btn btn-anchor-link no-min-width px-0 text-left"
                      onClick={handleShowDriveUpHours}
                      id="show-hide-drive-up-hours"
                    >
                      <Icon name={showDriveUpHours ? "chevron-up" : "chevron-down"} class="mr-1" lib="fas" />
                      {showDriveUpHours ? "Hide Hours" : "See Hours"}
                    </button>
                  </div>
                </div>
                {showDriveUpHours && <DailyHours {...branch.DriveUpHours} />}
              </>
            )}
            {/* Branch Phone Number */}
            <div className="mb-3">
              <Icon name="phone" lib="far" class="text-primary mr-2" />
              <a className="text-decoration-none" id="branch-phone-cta" href={`tel:${branch.PhoneNumber}`}>
                {branch.PhoneNumber}
              </a>
            </div>
            {/* Branch Email Address */}
            <div>
              <Icon name="envelope" lib="far" class="text-primary mr-2" />
              <a className="text-decoration-none" id="branch-email-cta" href={`mailto:${branch.EmailAddress}`}>
                {branch.EmailAddress}
              </a>
            </div>
          </div>
          <div className="col-md-6 d-none d-md-block">
            {branch.BranchImageUrl ? (
              <img
                src={branch.BranchImageUrl}
                className="border-radius-12 mw-100"
                alt={`WaFd Bank in ${branch.Address.City}, ${branch.Address.State} #${branch.BranchId} - Washington Federal.`}
              />
            ) : null}
          </div>
        </div>
      </section>

      {/* Branch Services Section */}
      <section className="container section-padding-adjusted" id="branch-information">
        <h2 className="text-success d-none d-md-block">Branch Information</h2>
        <div className="row">
          {/* Lobby Hours */}
          <div className="col-md-4 d-none d-md-block" id="branch-lobby-hours">
            <h3>Lobby Hours</h3>
            <DailyHours {...branch.LobbyHours} />
            <p>
              <button
                className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
                onClick={handleShowModal}
              >
                <Icon name="calendar" lib="far" class="mr-2" />
                2024 Holidays
              </button>
            </p>
          </div>
          {/* Current Year Holidays Modal */}
          <Modal show={showHolidayModal} onHide={handleCloseModal}>
            <Modal.Header className="border-0" closeButton></Modal.Header>
            <Modal.Body className="pt-0">
              <HolidaysModal />
            </Modal.Body>
            <Modal.Footer>
              <button
                className={`text-left text-black font-weight-bold ${styles.buttonTransparent}`}
                style={{ cursor: "pointer" }}
                onClick={handleCloseModal}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
          {/* Drive Thru Hours */}
          {branch.BranchServices.DriveThroughBanking && (
            <div className="col-md-4 mb-4 d-none d-md-block" id="branch-drive-thru-hours">
              <h3>Drive Thru Hours</h3>
              <DailyHours {...branch.DriveUpHours} />
            </div>
          )}
          <div className="col-md-4" id="branch-services">
            {/* Branch Services */}
            <h3>Branch Services</h3>
            <BranchServices
              {...{
                services: branch.BranchServices
              }}
            />
          </div>
          <div className="col-12 d-md-none mb-4">
            {branch.BranchImageUrl ? (
              <img
                src={branch.BranchImageUrl}
                className="border-radius-12 mw-100"
                alt={`WaFd Bank in ${branch.Address.City}, ${branch.Address.State} #${branch.BranchId} - Washington Federal.`}
              />
            ) : null}
          </div>
        </div>
        {/* Branch Manager and Loan Officers */}
        {loanOfficers.length > 0 && (
          <div id="branch-employees">
            <h2 className="text-success">Meet our {branch.Title} Team</h2>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
              {loanOfficers.map((loanOfficer, index) => {
                const idPrefix = `${nameToId(loanOfficer.FirstName)}-${nameToId(loanOfficer.LastName)}`;
                return (
                  <div className="col mb-4" key={index}>
                    <div className="card border-radius-12 shadow-sm">
                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col">
                            <h5 className="mb-0">
                              {loanOfficer.FirstName} {loanOfficer.LastName}
                            </h5>
                            <p className="mb-0">{loanOfficer.JobTitle?.replace(/([A-Z])/g, " $1").trim()}</p>
                          </div>
                          <div className="col-auto">
                            <BankerHeadshot email={loanOfficer.EmailAddress} width={64} height={64} />
                          </div>
                        </div>
                        <a
                          href={`mailto:${loanOfficer.EmailAddress}`}
                          className="text-decoration-none mb-3"
                          id={`${idPrefix}-email-address`}
                        >
                          <Icon name="envelope-open-text" lib="far" class="mr-2" />
                          {loanOfficer.EmailAddress}
                        </a>
                        {loanOfficer.PhoneNumber && (
                          <a
                            href={`tel:${loanOfficer.PhoneNumber}`}
                            className="text-decoration-none mb-3"
                            id={`${idPrefix}-phone-number`}
                          >
                            <Icon name="phone-alt" class="mr-2" />
                            {loanOfficer.PhoneNumber}
                          </a>
                        )}
                      </div>
                      <div className="card-footer border-radius-bottom-12 border-0 bg-white pt-0 pb-3">
                        <Link
                          id={`${idPrefix}-make-an-appointment`}
                          className="btn btn-primary btn-block"
                          to={`/make-an-appointment?bankerName=${loanOfficer.EmailAddress?.replace(
                            /@wafd.com/,
                            ""
                          ).replace(/\./g, "")}`}
                        >
                          Make an Appointment
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </section>
      {/* Icon Blocks */}
      <section className="container py-0" id="branch-icon-blocks-container">
        <h2 className="text-success">Online Services</h2>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
          {iconBlocksData.map((data, index) => (
            <div className="col mb-4 card border-0">
              <div
                className="border-radius-12 bg-blue-10 d-flex align-items-center p-3 h-100"
                key={index}
                id={`branch-icon-block-${index + 1}`}
              >
                {data.onClick ? (
                  <button
                    id={`branch-icon-block-${index + 1}-link`}
                    onClick={data.onClick}
                    className="btn-anchor-link stretched-link"
                  >
                    <span className="sr-only">{data.text}</span>
                  </button>
                ) : data.externalLink ? (
                  <a
                    id={`branch-icon-block-${index + 1}-link`}
                    className="stretched-link"
                    href={data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">{data.text}</span>
                  </a>
                ) : (
                  <Link id={`branch-icon-block-${index + 1}-link`} className="stretched-link" to={data.url}>
                    <span className="sr-only">{data.text}</span>
                  </Link>
                )}
                <img src={data.src} className="mr-3 d-none d-md-flex" alt={data.text} style={{ width: "74px" }} />
                <img src={data.src} className="mr-3 d-flex d-md-none" alt={data.text} style={{ width: "48px" }} />
                <p className="font-weight-bold mb-0 text-primary">
                  {data.text}
                  <Icon name="arrow-right" class="ml-2 text-primary" lib="fas" />
                </p>
              </div>
            </div>
          ))}
        </div>
        <LoanOfficerSearch
          isModalSearch={true}
          show={showLOModalMortgage}
          handleClose={handleCloseLOModalMortgage}
          loanType="Home Loan"
        />
        <LoanOfficerSearch
          isModalSearch={true}
          show={showLOModalHomeEquity}
          handleClose={handleCloseLOModalHomeEquity}
          loanType="HELOC" // can use both HELOC and HELOAN
        />
      </section>
      {statesWonAward.includes(StateCode) && (
        <section className="bg-light" id="best-bank-in-state">
          <div className="container">
            <h2 className="text-success">
              Best Bank in {branch.Address.State === "NewMexico" ? "New Mexico" : branch.Address.State}
            </h2>
            <div className="row">
              <div className="col-md-6">
                {StateCode === "CA" ? (
                  <>
                    <p>
                      Walk in our branch and you'll see this is your neighborhood bank, with bankers that care about
                      knowing your name and helping you find financial success right here in the community. Whether
                      you're a business in the San Jose area or an individual looking for banking services to help
                      strengthen your financial future, WaFd Bank can help. From{" "}
                      <Link
                        id="free-checking-link"
                        to={`/personal-banking/free-checking-account/${branch.branch_state.Slug}`}
                      >
                        {branch.Address.State} free checking
                      </Link>{" "}
                      accounts to{" "}
                      <Link id="small-business-banking-link" to="/business-banking/small-business">
                        small business banking
                      </Link>{" "}
                      and{" "}
                      <Link id="commercial-lending-link" to="/commercial-banking/commercial-lending">
                        commercial lending services
                      </Link>
                      , WaFd Bank is here for all of your banking needs.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Walk in our branch and you'll see this is your neighborhood bank, with bankers that care about
                      knowing your name and helping you find financial success right here in the community. Whether
                      you're a business in the {branch.Address.City} area or an individual looking for banking services
                      to help strengthen your financial future, WaFd Bank can help. From{" "}
                      <Link
                        id="free-checking-link"
                        to={`/personal-banking/free-checking-account/${branch.branch_state.Slug}`}
                      >
                        {branch.Address.State === "NewMexico" ? "New Mexico" : branch.Address.State} free checking
                      </Link>{" "}
                      accounts and{" "}
                      <Link id="home-loans-link" to="/personal-banking/home-loans">
                        mortgages
                      </Link>{" "}
                      to{" "}
                      <Link id="small-business-banking-link" to="/business-banking/small-business">
                        small business banking
                      </Link>{" "}
                      and{" "}
                      <Link id="commercial-lending-link" to="/commercial-banking/commercial-lending">
                        commercial lending services
                      </Link>
                      , WaFd Bank is here for all of your banking needs.
                    </p>
                    <p className="mb-md-0">
                      Or if you're looking for a great online and mobile banking experience, you can open an account or
                      bank with us anytime, anywhere, from your computer, tablet or smart phone.
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-lg-6 mb-4 mb-lg-0 text-center">
                    {StateCode === "ID" ? (
                      <BusinessReviewAwardImage />
                    ) : StateCode === "NV" ? (
                      <BestInStateBanksImage />
                    ) : (
                      <BestBanksImage />
                    )}
                  </div>
                  <div className="col-md-6 text-center">
                    {StateCode === "ID" ? (
                      <BestBanksImage />
                    ) : StateCode === "WA" || StateCode === "NM" ? (
                      <BestInStateBanksImage />
                    ) : null}
                  </div>
                </div>
                <h5 className="font-weight-normal mb-0">Awarded America's Best Big Bank by Newsweek</h5>
                <div className="mb-4">
                  <Link id={`best-banks-link-washington`} to="/about-us/best-bank/washington-state">
                    Washington
                  </Link>
                  ,{" "}
                  <Link id={`best-banks-link-idaho`} to="/about-us/best-bank/idaho">
                    Idaho
                  </Link>
                  ,{" "}
                  <Link id={`best-banks-link-new-mexico`} to="/about-us/best-bank/new-mexico">
                    New Mexico
                  </Link>
                </div>
                <h5 className="font-weight-normal mb-0">Awarded America's Best-in-State Bank by Forbes</h5>
                <Link id={`best-in-state-banks-link-washington`} to="/about-us/best-bank/washington-state">
                  Washington
                </Link>
                ,{" "}
                <Link id={`best-in-state-banks-link-nevada`} to="/about-us/best-bank/nevada">
                  Nevada
                </Link>
                ,{" "}
                <Link id={`best-in-state-banks-link-new-mexico`} to="/about-us/best-bank/new-mexico">
                  New Mexico
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Online Banking */}
      <section className="container border-bottom" id="mobile-online-banking">
        <div className="row">
          <div className="col-md-6 d-none d-md-block">
            <img src={WaFdMobileApp} className="w-100" alt="WaFd Bank Mobile App home screen" />
          </div>
          <div className="col-md-6">
            <h3 className="text-success font-weight-semi-bold">Convenient and Secure Online Banking</h3>
            <List {...onlineMobileBankingListItems} />
            <p className="text-success font-weight-bold text-sm d-none d-md-block">Get the WaFd Bank mobile app</p>
            <div className="d-block d-md-none">
              <img src={WaFdMobileApp} className="w-100 mb-4" alt="WaFd Bank Mobile App home screen" />
            </div>
            <div className="row">
              <div className="col-6 col-md-auto col-lg-5 col-xl-4 d-none d-md-block">
                <StaticImage
                  src="../../../images/wafd-mobile-app-qr-code.png"
                  alt="WaFd App QR Code"
                  placeholder="blurred"
                  quality="100"
                  className="mb-3"
                />
                <p className="text-gray-60 text-nowrap text-sm">Scan to download</p>
              </div>
              <div className="col d-flex flex-row flex-md-column justify-content-around">
                <div className="align-self-start">
                  <MobileAppDownloadBtn
                    idName="apple-store"
                    storeBtn="apple"
                    url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                  />
                  <p className="text-gray-60 text-nowrap text-sm text-center">For Apple devices</p>
                </div>
                <div className="align-self-start">
                  <MobileAppDownloadBtn
                    idName="google-play-store"
                    storeBtn="google"
                    url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                  />
                  <p className="text-gray-60 text-nowrap text-sm text-center">For Android devices</p>
                </div>
              </div>
            </div>
            <Link
              to="/personal-banking/online-banking"
              id="online-banking-page-link"
              className="btn btn-link w-100 w-md-auto mb-4"
            >
              WaFd Online Banking and Mobile App
            </Link>
            <Button {...mobileAndOnlineBankingFAQs} />
          </div>
        </div>
      </section>

      {branch.YextCode && (
        <section className="bg-white pb-0">
          <div className="container">
            <h3 className="text-success mb-0">Branch Reviews</h3>
            <div id="branch-reviews"></div>
          </div>
        </section>
      )}
      {!statesWonAward.includes(StateCode) && <BestBanksDefault />}
    </SecondaryLanding>
  );
};

BranchTemplate.propTypes = {
  branch: PropTypes.object
};

export default BranchTemplate;
